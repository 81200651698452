.levelProgressBarWrapper {

  .levelWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .level {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: #ffffff;
    margin-right: 20px;
    margin-left: 10px;

    span {
      line-height: 1.25;
      font-size: 32px;
    }
  }
}