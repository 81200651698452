.adminWorkContainer {
  position: relative;

  .header-admin {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    letter-spacing: 1.6px;
    color: #7b7b7b;
    .header-logo {
      padding: 24px 70px;
    }
    .content-admin {
      min-height: 640px;
    }
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 50;
    @media screen and (max-width: 1040px) {
      height: 110px;
    }

    @media screen and (max-width: 965px) {
      position: static;
      z-index: auto;
    }
    .accountInfo {
      display: flex;
      padding-left: 60px;
      @media screen and (max-width: 1130px) {
        padding-left: 60px;
        justify-content: space-around;
      }
      @media screen and (max-width: 1040px) {
        flex-direction: column;
      }
      @media screen and (max-width: 965px) {
        padding-left: 20px;
      }
      .accountName,
      .accountId {
        display: flex;
        align-items: center;
        .label {
          border-radius: 5px;
          background: #7b7b7b;
          color: #ffffff;
          font-family: "Noto Sans";
          font-style: normal;
          font-size: 12px;
          padding: 2px 5px;
          text-align: center;
          margin-right: 3px;
        }
        .value {
          color: #7b7b7b;
          margin: 0px;
          font-weight: 700;
          font-size: 16px;
          font-style: normal;
          max-width: 160px;
        }
      }

      .accountName {
        margin-right: 30px;
        @media screen and (max-width: 965px) {
          margin-right: 20px;
        }
        .value {
          min-width: 60px;
        }
      }

      .accountId {
        margin-right: 20px;
      }
    }
    .rightSideWrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: 965px) {
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .changePassword {
      display: flex;
      align-items: center;
      padding-right: 25px;
      padding-left: 30px;
      cursor: pointer;
      @media screen and (max-width: 965px) {
        padding-right: 25px;
      }
      p {
        margin: 0;
        color: #7b7b7b;
        font-weight: 700;
        font-size: 12px;
        font-family: "Noto Sans";
        font-style: normal;
        padding-left: 5px;
      }
    }
  }

  .layoutContentImage {
    justify-content: left !important;
  }

  .childrenWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .work-container {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;

      .leftSection,
      .rightSection {
        padding-bottom: 2rem;
        height: 100%;
        padding-top: 80px;
        @media screen and (max-width: 1040px) {
          padding-top: 120px;
        }
        @media screen and (max-width: 965px) {
          padding-top: 20px;
        }
      }

      .leftSection {
        background: #7b7b7b;
        background: linear-gradient(180deg, #7b7b7b 0%, #3e3e3e 100%);
        display: flex;
        flex-direction: column;
        max-width: 300px;
        width: 300px;
        padding-left: 30px;
        padding-right: 30px;

        .processNameLevel {
          flex-grow: 1;
          padding-top: 30px;

          @media screen and (max-width: 560px) {
            display: block;
          }
          
          .inner_wrapper {
            display: flex;
            align-items: center;

            &:nth-child(1) {
              @media screen and (max-width: 560px) {
                margin-bottom: 10px;
              }
            }
          }
          
          .process_Text, .process_Name {
            font-weight: 700;
            font-style: normal;
            color: white;
          }

          .process_Text {
            letter-spacing: .25rem;
          }

          .process_Name {
            letter-spacing: .15rem;
          }

          .process_Level {
            background-color: #7b7b7b;
            border-radius: 13px;
            font-family: "Roboto";
            font-size: 18px;
            font-weight: 500;
            margin: 0px;
            color: #ffffff;
            padding-left: 13px;
            padding-right: 13px;
            height: 25px;
          }
        }

        .levelBoostingWrapper {
          padding-bottom: 50px;
          .boostingImage {
            display: flex;
            align-items: center;
            
            .boostingWrapper {
              width: 100%;
            }
          }
          .levelProgressBarWrapper {
            .ant-progress .ant-progress-inner {
              background-color: #e2e2e2;
            }
          }
          
          .levelTagWrapper {
            .label {
              min-width: 7.75em;
            }
          }
        }
      }

      .rightSection {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 560px) {
          margin-right: 0px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .cycleTimeAvater {
          flex-grow: 1;
          display: flex;
          align-items: center;
          padding-left: 20px;
          padding-right: 5px;  
          @media screen and (max-width: 560px) {
            display: block;
            margin-right: 0px;
            padding-left: 20px;
            padding-right: 20px;
          }

          .cycleSpeedmeter {
            flex-grow: 1;
            height: 100%;
            position: relative;
            padding-left: 10px;
          }

          .avatarImageContainer,
          .newAvatarImageContainer {
            margin-left: -20px;
            width: 21vw;
            height: 100%;      
            .imgContainer {
              position: relative;
              img {
                width: 21vw;
                height: auto;
                top: 50%;
                transform: translateY(-55%);
              }
            }
          }
        }

        .totalDataWrapper {
          padding-left: 50px;
          padding-right: 30px;
          .totalDataRows {
            display: flex;
            .totalDataParts {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-right: 20px;

              &:first-child {
                margin-left: 50px;
                margin-right: 110px;
              }

              .total-number-work {
                text-align: center;
                background: #3098DF;
                width: 350px;
                height: 70px;
                line-height: 60px;
                color: white;
                position: relative;
                margin-top: 20px;
                
                .totalNumberWork__innerWrapper {
                  display: flex;
                  justify-content: space-between;
                  padding-left: 25px;
                  padding-right: 25px;
                  align-items: center;
                  height: 100%;

                  .legend {
                    display: flex;
                    align-items: center;

                    p {
                      padding-left: 5px;
                      font-family: "Noto Sans";
                      font-size: 18px;
                      font-weight: 700;
                      font-style: normal;
                      color: #ffffff;
                      margin: 0;
                    }
                  }

                  .data {
                    font-family: Roboto;
                    font-size: 44px;
                    font-weight: 700;
                    font-style: normal;
                    color: #ffffff;
                    margin: 0;
                    margin-left: 0.75rem;
                  }
                }

                &:before {
                  content: "";
                  width: 0px;
                  height: 0px;
                  border-top: 70px solid #3098DF;
                  border-left: 70px solid transparent;
                  position: absolute;
                  right: 100%;
                  top: 0px;
                }

                &.total-number-work-sub {
                  width: 340px;
                }
              }

              .total-number-time {
                text-align: center;
                background: #F9964C;
                width: 350px;
                height: 70px;
                line-height: 60px;
                color: white;
                position: relative;
                margin-top: 20px;

                .totalNumberTime__innerWrapper {
                  display: flex;
                  justify-content: space-between;
                  padding-left: 25px;
                  padding-right: 25px;
                  align-items: center;
                  height: 100%;

                  .legend {
                    display: flex;
                    align-items: center;

                    p {
                      padding-left: 5px;
                      font-family: "Noto Sans";
                      font-size: 18px;
                      font-weight: 700;
                      font-style: normal;
                      color: #ffffff;
                      margin: 0;
                    }
                  }

                  .data {
                    font-family: Roboto;
                    font-size: 44px;
                    font-weight: 700;
                    font-style: normal;
                    color: #ffffff;
                    margin: 0;
                    margin-left: 0.75rem;
                  }
                }

                &:before {
                  content: "";
                  width: 0px;
                  height: 0px;
                  border-top: 70px solid #F9964C;
                  border-left: 70px solid transparent;
                  position: absolute;
                  right: 100%;
                  top: 0px;

                  @media screen and (max-width: 965px) {
                    border: none;
                  }
                }

                &.total-number-time-sub {
                  min-width: 340px;
                  width: auto;
                }
              }
            }
          }
        }
      }
    
    }
  }
}
.ant-progress .ant-progress-steps-outer {
  gap: 3px !important;
}
