.levelTagWrapper {
  display: flex;
  align-items: center;

  .label {
    border-radius: 5px;
    color: #ffffff;
    font-family: "Noto Sans";
    font-style: normal;
    font-size: 12px;
    padding: 2px 5px;
    text-align: center;
    margin-right: 3px;
    min-width: 80px;
  }

  .lvlValue {
    margin: 0px;
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
  }

  &.worker {
    .label {
      background-color: #054B7B;
    }
    .lvlValue {
      color: #054B7B;
    }
  }

  &.boost {
    .label {
      background-color: #D0811A;
    }
    .lvlValue {
      color: #054B7B;
    }
  }

  &.process {
    .label {
      background-color: #35AD63;
    }
    .lvlValue {
      color: #35AD63;
    }
  }

  &.skillCheck {
    margin-top: 10px;

    .label {
      font-weight: 700;
      padding: 6px 14px;
      margin-right: 10px;
      min-width: 80px;
    }
  }

  &.notiEffect {
    .label {
      font-size: 16px;
      padding: 2px 14px;
    }
  }
}