.speedmeterContainer {
  height: 100%;
  & > div {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .speedometer {
      object-fit: fill;
    }
  }
}

.spinContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .ant-spin-dot {
    font-size: 65px;

    .ant-spin-dot-item {
      width: 32px;
      height: 32px;
    }
  }
}